export const screenWidth = '1126px';
export const transitionTime = '300ms';
export const textColor = 'rgb(40,40,40)';
export const lightBorderColor = 'rgb(235,235,235)';
export const lightBackgroundColor = 'rgb(250,250,250)';
export const mediumBackgroundColor = 'rgba(156, 156, 156, 0.5)';
export const darkBackgroundColor = 'rgb(156, 156, 156)';

export const photoDimension = '345px';
export const smallPhotoDimension = '300px';

export const imageBase = 'https://d12je7k9pwes60.cloudfront.net/';

export const iPhone5Breakpoint = '320px';
export const smallBreakpoint = '500px';
export const mediumBreakpointMinus = '749px';
export const mediumBreakpoint = '750px';
export const largeBreakpointMinus = '1124px';
export const largeBreakpoint = '1125px';

export const sliderSettingsBase = {
  dots: true,
  infinite: true,
  arrows: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  draggable: true,
  centerMode: false,
};

export const menuLinks = {
  'Shop Art': '/#works',
  'Shop Small Art': '/small-works',
  'Art Information': '/more-info',
  Commissions: '/commissions',
  Exhibitions: '/exhibitions',
  About: '/about',
  Contact: '/contact',
  Blog: '/blog',
  Reviews: '/reviews',
};
