import React, { Component } from 'react';
import loadable from '@loadable/component';
import styled from 'styled-components';

const InfoPage = loadable(() => import('../components/InfoPage'));
const ContentBox = loadable(() =>
  import('../components/sub-components/contentBox')
);
import { mediumBreakpointMinus } from '../constants';

const BrowserView = styled.div`
  display: block;
  @media only screen and (max-width: ${mediumBreakpointMinus}) {
    display: none;
  }
`;

const MobileView = styled.div`
  display: none;
  @media only screen and (max-width: ${mediumBreakpointMinus}) {
    display: block;
  }
`;

class Purchase extends Component {
  render() {
    return (
      <InfoPage
        title="Jennifer Mone Hill Artist - Purchase Art"
        description="How to purchase. Check availability and commission artwork from Jennifer Mone Hill."
        keywords={[
          'buy art',
          'buy Jennifer Moné Hill art',
          'Jen Hill Artist',
          'Jennifer Mone Hill',
          'Jen Hill',
          'Jen Hill Artist',
          'how to purchase',
          'corporate art',
          'interior art',
          '2D art',
          'paintings',
          'buy paintings',
          'buy modern art',
          'buy abstract art',
          'availability',
          'commission art',
          'one of a kind',
          'original art',
          'buy original art',
        ]}
        imageLink="/works/Roseate"
        imgSrc="Roseate-by-Jennifer-Mone-Hill-800.jpg"
        imgSrc2x="Roseate-by-Jennifer-Mone-Hill-1600.jpg"
        alt="Roseate by Jennifer Moné Hill"
      >
        <ContentBox>
          <BrowserView>
            <h3>
              Availability &nbsp;&bull;&nbsp; Pricing &nbsp;&bull;&nbsp;
              Purchase
            </h3>
          </BrowserView>
          <MobileView>
            <h3>
              Availability
              <br />
              Pricing
              <br />
              Purchase
            </h3>
          </MobileView>
          <ul>
            <li>
              <p>
                Please{' '}
                <a href="mailto:jmonehill@gmail.com?subject=Purchase Inquiry">
                  email
                </a>{' '}
                any pre-purchase inquiries directly to Jen about the painting you’re interested in.
              </p>
            </li>
            <li>
              <p>Each work has a button to purchase.</p>
            </li>
            <li>
              <p>
                Local purchases may be picked up, in Danbury, CT or delivered.
                Ask for details.
              </p>
            </li>
          </ul>
        </ContentBox>
        <ContentBox>
          <h3>Commissions</h3>
          <ul>
            <li>
              <p>Commission your own one of a kind, custom piece.</p>
            </li>
            <li>
              <p>
                <a href="mailto:jmonehill@gmail.com?subject=Purchase Inquiry">
                  Email
                </a>{' '}
                Jen to set up a consultation.
              </p>
            </li>
            <li>
              <p>
                Commission pieces are priced the same as other artwork, giving
                you the ability to customize to your desired size and colors. To
                be clear, there is something left to the muses when creating
                abstract art. No two are alike.
              </p>
            </li>
            <li>
              <p>50% deposit required.</p>
            </li>
          </ul>
        </ContentBox>
      </InfoPage>
    );
  }
}

export default Purchase;
